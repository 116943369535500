import React from 'react';
import Container from '../../components/Container';
import { PageHeader } from '../../components/Headers';
import GasCalcSection from '../../components/GasCalcSection';
import { Helmet } from 'react-helmet-async';

function GasComparison() {
  return (
    <>
      <Helmet>
        <title>Optimism | Gas Comparison</title>
        <meta
          name="twitter:image"
          content={`${window.location.origin}/images/social-card-gas-comparison.png`}
        />
      </Helmet>
      <Container maxW="1200px">
        <PageHeader>Gas Comparison</PageHeader>
        <GasCalcSection />
      </Container>
    </>
  );
}

export default GasComparison;
