import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './pages/Homepage';
import NotFound from './pages/NotFound';
import Demos from './pages/Demos';
import TxHistory from './pages/TxHistory';
import NetworkStatus from './pages/NetworkStatus';
import GasComparison from './pages/GasComparison';
import AnnouncementBanner from './components/AnnouncementBanner';
import { images } from './utils/constants';
import { Helmet } from 'react-helmet-async';
import { Box, useMediaQuery } from '@chakra-ui/react';

function App() {
  const [isBannerOpen, setIsBannerOpen] = React.useState(false);
  const [smallScreen] = useMediaQuery('(max-width: 1000px)');

  React.useEffect(() => {
    // removes blue outline when user is using mouse
    function handleFirstTab(e) {
      if (e.keyCode === 9) {
        document.body.classList.add('user-is-tabbing');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
      }
    }

    function handleMouseDownOnce() {
      document.body.classList.remove('user-is-tabbing');

      window.removeEventListener('mousedown', handleMouseDownOnce);
      window.addEventListener('keydown', handleFirstTab);
    }

    window.addEventListener('keydown', handleFirstTab);

    // preloads images
    window.onload = () => {
      Object.values(images).forEach((imgSrc, i) => {
        const img = new Image();
        img.src = '/images/' + imgSrc;
      });
    };
  }, []);

  React.useEffect(() => {
    // Show banner if it hasn't been shown yet
    const hasSeenBanner = localStorage.getItem('HAS_SEEN_BANNER');
    if (!hasSeenBanner) {
      setIsBannerOpen(true);
    }
  }, []);

  const handleBannerClose = () => {
    localStorage.setItem('HAS_SEEN_BANNER', true);
    setIsBannerOpen(false);
  };

  const bannerHeight = smallScreen ? '100px' : '40px';

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <meta name="theme-color" content="#FF0420" />
        <meta
          name="description"
          content="Optimism is a Public Benefit Corporation dedicated to scaling Ethereum"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1951" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:alt" content="Optimism" />
        <meta
          property="og:image:url"
          content={`${window.location.origin}/images/social-card-dark.png`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/images/social-card-dark.png`}
        />
        <meta name="twitter:site" content="https://optimism.io" />
        <meta name="twitter:title" content="Optimism" />
        <meta
          name="twitter:description"
          content="Optimism is a Public Benefit Corporation dedicated to scaling Ethereum"
        />
        <script
          async
          defer
          data-domain="optimism.io"
          src="https://plausible.io/js/plausible.js"
        ></script>
        ><title>Optimism</title>
      </Helmet>
      <AnnouncementBanner
        isOpen={isBannerOpen}
        handleClose={handleBannerClose}
        height={bannerHeight}
      />
      <Box
        className="App"
        transform={
          isBannerOpen ? `translateY(${bannerHeight})` : 'translateY(0)'
        }
        transition="transform 300ms"
      >
        <Navigation />
        <ScrollToTop />
        <Switch>
          {/* The Switch decides which component to show based on the current URL.*/}
          <Route exact path="/" component={Home} />
          <Route path="/demos" component={Demos} />
          <Route path="/status" component={NetworkStatus} />
          <Route exact path="/snx-history">
            <Redirect to="/withdrawal-history" /> : <TxHistory />
          </Route>
          <Route path="/withdrawal-history" component={TxHistory} />
          <Route path="/gas-comparison" component={GasComparison} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Box>
    </>
  );
}

export default App;
