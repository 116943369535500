import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Link, Container } from '@chakra-ui/react';

function AnnouncementBanner({ children, isOpen, handleClose, height }) {
  return (
    <Box
      h={height}
      bg="brandPrimary"
      transform={isOpen ? 'translateY(0px)' : `translateY(-${height})`}
      transition="transform 300ms"
      pos="fixed"
      w="100%"
      zIndex="1"
      color="white"
      fontWeight="bold"
      d="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container
        d="flex"
        justifyContent="center"
        alignItems="center"
        maxW="1200px"
      >
        <Box pl="1rem" pr="2rem">
          Uniswap has deployed on Optimistic Ethereum. To try it out, deposit
          funds via our{' '}
          <Link
            href="https://gateway.optimism.io?ref=home"
            isExternal
            color="white"
            textDecoration="underline"
            _hover={{ color: 'white' }}
          >
            Gateway
          </Link>
          , then head over to{' '}
          <Link
            href="https://app.uniswap.org/"
            isExternal
            color="white"
            textDecoration="underline"
            _hover={{ color: 'white' }}
          >
            Uniswap
          </Link>
          !
        </Box>
      </Container>
      <Button
        onClick={handleClose}
        pos="absolute"
        right="0.5rem"
        bg="transparent"
        border="none"
        cursor="pointer"
        padding={0}
        margin={0}
        _focus={{ bg: 'transparent' }}
        _active={{ bg: 'transparent' }}
        _hover={{ bg: 'transparent' }}
      >
        <CloseIcon color="white" />
      </Button>
    </Box>
  );
}

export default AnnouncementBanner;
