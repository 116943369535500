import React from 'react';
import {
  Box,
  Stack,
  Center,
  VStack,
  Heading,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { JsonRpcProvider, InfuraProvider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import Container from '../../components/Container';
import { PageHeader } from '../../components/Headers';
import NetworkCard from '../../components/NetworkCard';
import { abis } from '@optimism/common';
import { Sentry } from '../../utils/sentry';

const isBrowserSupported = typeof Object.fromEntries === 'function';

const KOVAN_STC_ADDRESS = '0xa2487713665AC596b0b3E4881417f276834473d2';
const MAINNET_STC_ADDRESS = '0xE969C2724d2448F1d1A6189d3e2aA1F37d5998c1';

export const networkStatuses = {
  HAPPY: 'HAPPY',
  CONTENT: 'CONTENT',
  CONFUSED: 'CONFUSED',
  SAD: 'SAD',
};

const BATCH_SUBMITTER_DELAYED = (
  <Box d="flex">
    <Box mr={4} role="img" ariaLabel="Construction">
      🚧
    </Box>{' '}
    Batch submitter delayed
  </Box>
);

function NetworkStatus() {
  const [smallScreen] = useMediaQuery('(max-width: 900px)');
  const [kovanState, setKovanState] = React.useState({});
  const [mainnetState, setMainnetState] = React.useState({});
  const [mainnetMillisSinceLastBatch, setMainnetMillisSinceLastBatch] =
    React.useState(0);
  const [kovanMillisSinceLastBatch, setKovanMillisSinceLastBatch] =
    React.useState(0);

  React.useEffect(() => {
    if (!isBrowserSupported) return;
    const kovanL1Rpc = new InfuraProvider('kovan');
    const kovanL2Rpc = new JsonRpcProvider(`https://kovan.optimism.io`);
    const mainnetL1Rpc = new InfuraProvider('mainnet');
    const mainnetL2Rpc = new JsonRpcProvider(
      `https://optimism-mainnet.infura.io/v3/9f0c70345c8d4f9ea915af6a6141cf70`,
      10
    );

    const kovanStateCommitChain = new Contract(
      KOVAN_STC_ADDRESS,
      abis.l1.OVM_StateCommitmentChain,
      kovanL1Rpc
    );

    const mainnetStateCommitChain = new Contract(
      MAINNET_STC_ADDRESS,
      abis.l1.OVM_StateCommitmentChain,
      mainnetL1Rpc
    );

    mainnetL2Rpc
      .getBlockNumber()
      .then(async () => {
        const millisSinceLastBatch = await getMillisSinceLastBatch(
          mainnetStateCommitChain,
          mainnetL2Rpc
        );
        if (millisSinceLastBatch) {
          setNetworkState(millisSinceLastBatch, setMainnetState);
          setMainnetMillisSinceLastBatch(millisSinceLastBatch);
        } else {
          setMainnetState((state) => ({
            ...state,
            status: networkStatuses.CONFUSED,
          }));
          console.error('Nothing returned from getMillisSinceLastBatch');
        }
      })
      .catch((err) => {
        console.error(err);
        setMainnetState((state) => ({ ...state, status: networkStatuses.SAD }));
        Sentry.captureException(err);
      });
    kovanL2Rpc
      .getBlockNumber()
      .then(async () => {
        const millisSinceLastBatch = await getMillisSinceLastBatch(
          kovanStateCommitChain,
          kovanL2Rpc
        );
        if (millisSinceLastBatch) {
          setNetworkState(millisSinceLastBatch, setKovanState);
          setKovanMillisSinceLastBatch(millisSinceLastBatch);
        } else {
          setKovanState((state) => ({
            ...state,
            status: networkStatuses.CONFUSED,
          }));
          console.error('Nothing returned from getMillisSinceLastBatch');
        }
      })
      .catch((err) => {
        console.error(err);
        setKovanState((state) => ({ ...state, status: networkStatuses.SAD }));
        Sentry.captureException(err);
      });

    setTimeout(() => {
      // This is only to handle setting a status if the others take too long to respond and likely means the sequencer is borked
      setMainnetState((state) =>
        state.status
          ? state
          : {
              status: networkStatuses.SAD,
              heading: 'Experiencing problems',
              batchSubmitter: state.batchSubmitter || BATCH_SUBMITTER_DELAYED,
            }
      );
      setKovanState((state) =>
        state.status
          ? state
          : {
              status: networkStatuses.SAD,
              heading: 'Experiencing problems',
              batchSubmitter: state.batchSubmitter || BATCH_SUBMITTER_DELAYED,
            }
      );
    }, 8000);

    const getMillisSinceLastBatch = async (stateCommitChain, provider) => {
      try {
        const lastTimestampBN =
          await stateCommitChain.getLastSequencerTimestamp();
        if (lastTimestampBN) {
          const lastTimestamp = Number(lastTimestampBN.toString() * 1000);
          // if lastTimestamp is zero, it means a regenesis just happened
          return lastTimestamp === 0 ? 0 : Date.now() - lastTimestamp;
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    };

    const setNetworkState = (millisSinceLastBatch, setter) => {
      const SEQUENCER_UP = (
        <Box d="flex">
          <Box mr={4} role="img" ariaLabel="Green checkmark">
            ✅
          </Box>
          Sequencer running
        </Box>
      );
      const moreThan24HoursAgo = millisSinceLastBatch > 1000 * 60 * 60 * 24;
      const moreThan2pt5HoursAgo = millisSinceLastBatch > 1000 * 60 * 60 * 2.5;
      if (moreThan24HoursAgo) {
        setter((state) => ({
          ...state,
          status: networkStatuses.CONFUSED,
          heading: 'Experiencing delays',
          batchSubmitter: (
            <Box d="flex">
              <Box mr={4} role="img" ariaLabel="Construction">
                🚧
              </Box>
              Withdrawals temporarily delayed
            </Box>
          ),
          sequencer: SEQUENCER_UP,
        }));
      } else if (moreThan2pt5HoursAgo) {
        setter((state) => ({
          ...state,
          status: networkStatuses.CONTENT,
          heading: 'System status',
          batchSubmitter: BATCH_SUBMITTER_DELAYED,
          sequencer: SEQUENCER_UP,
        }));
      } else {
        setter((state) => ({
          ...state,
          status: networkStatuses.HAPPY,
          heading: 'All systems operational!',
          batchSubmitter: (
            <Box d="flex">
              <Box mr={4} role="img" ariaLabel="Green checkmark">
                ✅
              </Box>
              Batch submitter running
            </Box>
          ),
          sequencer: SEQUENCER_UP,
        }));
      }
    };
  }, []);

  return (
    <Container>
      <PageHeader mb={16}>System Status</PageHeader>
      {isBrowserSupported ? (
        <Box>
          <Stack
            spacing={smallScreen ? 8 : 24}
            direction={['column', null, 'row']}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <NetworkCard
              networkName={'Optimistic Mainnet'}
              smallScreen={smallScreen}
              millisSinceLastBatch={mainnetMillisSinceLastBatch}
              networkState={mainnetState}
            />
            <NetworkCard
              networkName={'Optimistic Kovan'}
              smallScreen={smallScreen}
              millisSinceLastBatch={kovanMillisSinceLastBatch}
              networkState={kovanState}
            />
          </Stack>
        </Box>
      ) : (
        <Center>
          <VStack spacing={16} maxW="500px" textAlign="center">
            <Heading fontWeight="normal" mb={4} fontSize={'18px'}>
              Your browser isn't supported. Please try upgrading or try a
              different browser (ex: the latest version of Chrome)
            </Heading>
            <Image
              src={'/images/optimism-logo.svg'}
              alt="Optimism PBC"
              maxW="200px"
              mx="auto"
            />
          </VStack>
        </Center>
      )}
    </Container>
  );
}

export default NetworkStatus;
