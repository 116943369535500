import React from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { gasComparisonValues } from '../../utils/constants';

const batchSubmissionGasMsg = (
  <>
    7400 is the estimated gas to submit the transaction on layer 1, based on a
    batch of 200 transactions.
    <p>Breakdown:</p>
    <ul>
      <li>1600: state root submission cost</li>
      <li>+ 2600: tx batch submission cost</li>
      <li>
        + 3200: non-input data cost of the transaction (e.g. signature, nonce,
        gasPrice, gasLimit, etc)
      </li>
    </ul>
  </>
);
const optimismPremiumMsg = '50% premium directed to public goods.';

const TTip = ({ label, children }) => (
  <Tooltip
    label={label}
    hasArrow
    bg="white"
    border="1px solid #ccc"
    boxShadow="0px 5px 10px 0px rgba(0,0,0,0.4)"
    color="black"
    borderRadius="5px"
    placement="top"
    fontSize="1rem"
    p={4}
  >
    {children}
  </Tooltip>
);

function Formula({ variables }) {
  let optimismPremium = 'optimismPremium';
  let zeroDataBytes = 'zeroDataBytes';
  let nonZeroDataBytes = 'nonZeroDataBytes';
  let layer1GasPrice = 'layer1GasPrice';
  let layer1Gas = 'layer1Gas';
  let layer2GasPrice = 'layer2GasPrice';
  let layer2Gas = 'layer2Gas';
  let layer2GasFee;

  if (variables) {
    ({
      zeroDataBytes,
      nonZeroDataBytes,
      layer1GasPrice,
      layer2GasPrice,
      layer2Gas,
    } = variables);
    optimismPremium = gasComparisonValues.OPTIMISM_PREMIUM;
    layer1Gas =
      zeroDataBytes * 4 +
      nonZeroDataBytes * 16 +
      gasComparisonValues.BATCH_SUBMISSION_GAS;
    layer2GasFee = layer1Gas * variables.layer1GasPrice;
  }

  return (
    <Box
      d="flex"
      flexDir="column"
      justifyContent="flex-start"
      as="code"
      bg="#f4f4f4"
      borderRadius="10px"
      className="js"
      height="100%"
      px={2}
    >
      <Box p={2} pb={0}>
        <TTip label={batchSubmissionGasMsg}>
          <Box d="inline" cursor="pointer">
            <Box as="span" color="pink.600">
              const&nbsp;
            </Box>
            <Box as="span" color="purple.700">
              batchSubmissionGas&nbsp;
            </Box>
            <Box as="span" color="pink.600">
              =&nbsp;
            </Box>
            <Box as="span" color="blue.500">
              {' '}
              7400
            </Box>
          </Box>
        </TTip>
      </Box>
      <Box p={2} pt={0}>
        <TTip label={optimismPremiumMsg}>
          <Box d="inline" cursor="pointer">
            <Box as="span" color="pink.600">
              const&nbsp;
            </Box>
            <Box as="span" color="purple.700">
              optimismPremium&nbsp;
            </Box>
            <Box as="span" color="pink.600">
              =&nbsp;
            </Box>
            <Box as="span" color="blue.500">
              {' '}
              1.5
            </Box>
          </Box>
        </TTip>
      </Box>
      <Box p={2}>
        <Box as="span" color="pink.600">
          const&nbsp;
        </Box>
        <Box as="span" color="purple.700">
          layer1Gas&nbsp;
        </Box>
        <Box as="span" color="pink.600">
          =&nbsp;
        </Box>
        <Box d="inline">
          <TTip
            label={
              "The number of zero/null bytes in the transaction's call data"
            }
          >
            <Box
              cursor="pointer"
              as="span"
              color={variables ? 'blue.500' : 'purple.700'}
            >
              {zeroDataBytes}
            </Box>
          </TTip>
          <Box as="span" color="pink.600">
            {' '}
            *{' '}
          </Box>
          <TTip label={'Each zero/null byte costs 4 gas'}>
            <Box cursor="pointer" as="span" color="blue.500">
              {' '}
              4
            </Box>
          </TTip>
        </Box>

        <Box d="inline">
          <Box as="span" color="pink.600">
            {' '}
            +{' '}
          </Box>
          <TTip label="The number of non-zero bytes in the transaction's call data">
            <Box
              cursor="pointer"
              as="span"
              color={variables ? 'blue.500' : 'purple.700'}
            >
              {nonZeroDataBytes}{' '}
            </Box>
          </TTip>
          <Box as="span" color="pink.600">
            {' '}
            *{' '}
          </Box>{' '}
          <TTip label={'Each non-zero byte costs 16 gas'}>
            <Box cursor="pointer" as="span" color="blue.500">
              16
            </Box>
          </TTip>
        </Box>
        <Box as="span" color="pink.600">
          {' '}
          +{' '}
        </Box>
        <TTip label={batchSubmissionGasMsg}>
          <Box cursor="pointer" as="span" color="purple.700">
            {' '}
            batchSubmissionGas
          </Box>
        </TTip>
      </Box>

      <Box p={2}>
        <Box as="span" color="pink.600">
          const&nbsp;
        </Box>
        <Box as="span" color="purple.700">
          layer2GasFee&nbsp;
        </Box>
        <Box as="span" color="pink.600">
          =&nbsp;
        </Box>
        <Box as="span" color="pink.600">
          (&nbsp;
        </Box>
        <TTip label="The current price per unit of gas on layer 1.">
          <Box
            cursor="pointer"
            as="span"
            color={variables ? 'blue.500' : 'purple.700'}
          >
            {layer1GasPrice}
          </Box>
        </TTip>
        <Box as="span" color="pink.600">
          {' '}
          *{' '}
        </Box>
        <TTip label="The total amount of layer 1 gas required to submit the transaction on layer 2.">
          <Box
            cursor="pointer"
            as="span"
            color={variables ? 'blue.500' : 'purple.700'}
          >
            {layer1Gas}{' '}
          </Box>
        </TTip>
        <Box as="span" color="pink.600">
          {' '}
          +{' '}
        </Box>
        <TTip label="The price per gas on layer 2. This will remain zero until Optimism is processing over ~2.5x the amount of execution as L1 Ethereum.">
          <Box
            cursor="pointer"
            as="span"
            color={variables ? 'blue.500' : 'purple.700'}
          >
            {layer2GasPrice}
          </Box>
        </TTip>
        <Box as="span" color="pink.600">
          {' '}
          *{' '}
        </Box>
        <TTip label="The amount of gas required to execute the transaction on Optimism.">
          <Box
            cursor="pointer"
            as="span"
            color={variables ? 'blue.500' : 'purple.700'}
          >
            {layer2Gas}
          </Box>
        </TTip>
        <Box as="span" color="pink.600">
          &nbsp;)
        </Box>
        <Box as="span" color="pink.600">
          {' '}
          *{' '}
        </Box>
        <TTip label={optimismPremiumMsg}>
          <Box
            cursor="pointer"
            as="span"
            color={variables ? 'blue.500' : 'purple.700'}
          >
            {optimismPremium}
          </Box>
        </TTip>
        <Box
          pt={4}
          color="#718096 !important"
          dangerouslySetInnerHTML={{
            __html: !variables
              ? ''
              : `// layer2GasFee === ${layer2GasFee} GWEI (${
                  0.000000001 * layer2GasFee
                } ETH)`,
          }}
        />
      </Box>
    </Box>
  );
}

export default Formula;
