import React, { Component } from 'react';
import { Center, Heading, Text, Box, Link } from '@chakra-ui/react';
import Container from '../Container';
import ButtonLink from '../ButtonLink';
import { images } from '../../utils/constants';
class Hero extends Component {
  render() {
    return (
      <Container>
        <Center height="calc(100vh - 50px)">
          <Box
            mb={16}
            textAlign={['center', null, 'left']}
            d="flex"
            flexDir="column"
            alignItems="center"
          >
            <Heading
              style={{ userSelect: 'none' }}
              as="h1"
              fontFamily="serifFont"
              fontWeight="bold"
              color="headingText"
              fontSize={['4xl', '5xl', '6xl']}
              mt={0}
              mb={4}
            >
              The{' '}
              <Box
                as="span"
                px={1}
                color="brandPrimary"
                className="rainbowText"
              >
                New
              </Box>{' '}
              Scalability
              <br /> Stack for{' '}
              <Box
                as="span"
                px={1}
                color="brandPrimary"
                className="rainbowText"
              >
                Ethereum
              </Box>
            </Heading>
            <Text
              fontSize={['lg', 'lg', 'xl']}
              d="flex"
              flexWrap="wrap"
              justifyContent="center"
              mt={8}
              mb={[6, null, 10]}
            >
              <Box as="span" whiteSpace="pre">
                Get started by depositing{' '}
              </Box>
              <Box as="span" whiteSpace="pre">
                or deploying!
              </Box>
            </Text>
            <span>
            <Link
              color="white"
              fontSize={['1.4rem', null, '1.8rem']}
              padding={['0.5rem 1rem', null, '0.8rem 1.6rem']}
              borderRadius="10px"
              href="https://gateway.optimism.io/?ref=home"
              className="rainbowBg"
              textTransform="uppercase"
              fontWeight="600"
              fontStyle="italic"
              fontFamily="var(--chakra-fonts-heading)"
              width="100%"
              maxW="300px"
              textAlign="center"
              marginRight="1rem"
              isExternal={true}
            >
              OΞ Gateway
            </Link>
            <Link
              color="white"
              fontSize={['1.4rem', null, '1.8rem']}
              padding={['0.5rem 1rem', null, '0.8rem 1.6rem']}
              borderRadius="10px"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfBGsJN3nZQRLdMjqCS_svfQoPkn35o_cc4HUVnLlXN2BHmPw/viewform"
              className="rainbowBg"
              textTransform="uppercase"
              fontWeight="600"
              fontStyle="italic"
              fontFamily="var(--chakra-fonts-heading)"
              width="100%"
              maxW="300px"
              textAlign="center"
              isExternal={true}
            >
              OΞ Whitelist
            </Link>
            </span>
            <Box
              backgroundPosition="right bottom"
              height="50vw"
              maxH="50vh"
              width="50vw"
              right={0}
              bottom={0}
              position="absolute"
              zIndex={-1}
              backgroundImage={`url(images/${images.hero})`}
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
            />
          </Box>
        </Center>
      </Container>
    );
  }
}

export default Hero;
