import React from 'react';
import {
  Input,
  Text,
  Heading,
  Box,
  Image,
  Button,
  Spinner,
  Center,
  Divider,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  SimpleGrid,
  Link,
} from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';
import styles from './GasCalc.module.css';
import { ethers } from 'ethers';
import axios from 'axios';
import coloredEthLogo from './coloredEthLogo.svg';
import Formula from './Formula';
import GasCalcResults from './GasCalcResults';
import { gasComparisonValues } from '../../utils/constants';

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const options = {
  chainlink: {
    name: 'Chainlink',
    link: 'https://etherscan.io/tx/0x96f6bd81e21d704f54fe3597e983b09daf27953e15a52ac5c632e6635f3be022',
    desc: 'Chainlink FluxAggregator feed update',
    iconURL:
      'https://pbs.twimg.com/profile_images/1030475757892579334/qvSHhRyC_400x400.jpg',
  },
  uniswap: {
    name: 'Uniswap v3 swap',
    link: 'https://etherscan.io/tx/0x4749bf6aec4410dd4a0bbe7588b5319034ac0f94c7b05defecd1984278b29a4d',
    desc: 'Uniswap trade',
    iconURL:
      'https://pbs.twimg.com/profile_images/1242184851152928769/wG2eTAfD_400x400.jpg',
  },
  synthetix: {
    name: 'Synthetix',
    link: 'https://etherscan.io/tx/0x4a9f870058003b9a996d1cc0acd3910cd1234ace19a820fb908fff7b933448eb',
    desc: 'Burn synths',
    iconURL: '/images/snx-logo.png',
  },
  makerdao: {
    name: 'MakerDAO',
    link: 'https://etherscan.io/tx/0x0c3b6a407d8ca407fd38409e75035f9e2df6b04a234e073e74284f0ed273c066',
    desc: 'MakerDAO pooled ETH conversion',
    iconURL: '/images/mkr-logo.png',
  },
  compound: {
    name: 'Compound',
    link: 'https://etherscan.io/tx/0x425a7df29f7713b874c1177c80617bcab34bc87fdb5120e9f763dcda1d596b5c',
    desc: 'Borrow DAI',
    iconURL: '/images/comp-logo.png',
  },
};

const optionLinks = Object.values(options).map((o) => o.link);

const GasCalcSection = () => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [txId, setTxId] = React.useState('chainlink');
  const [etherscanLink, setEtherscanLink] = React.useState(
    txId ? options[txId].link : null
  );
  const [isCalculating, setIsCalculating] = React.useState(true);
  const [l1Gas, setL1Gas] = React.useState(0);
  const [l2Gas, setL2Gas] = React.useState(0);
  const [zeroBytes, setZeroBytes] = React.useState(0);
  const [dataBytes, setDataBytes] = React.useState(0);
  const [gasSaved, setGasSaved] = React.useState(0);
  const [l1GasPrice, setL1GasPrice] = React.useState(0);
  const [l2UsdPrice, setL2UsdPrice] = React.useState(0);
  const [showHeading, setShowHeading] = React.useState(true);
  const toast = useToast();

  const handleInputOverride = (id) => {
    setTxId(id);
    setEtherscanLink(options[id].link);
    setShowHeading(true);
    handleFormSubmit(null, options[id].link);
  };

  const handleFormSubmit = React.useCallback(
    async (e, link) => {
      if (!link) {
        setTxId(null);
      }
      const txLink = link || etherscanLink;
      if (e) e.preventDefault();
      const validLink = isValidLink(txLink);
      if (!validLink) {
        toast({
          title: 'Invalid link',
          description: 'Please check the link and try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setIsCalculating(true);
      setShowHeading(optionLinks.includes(link));

      try {
        const connectionInfo = {};
        if (process.env.NODE_ENV === 'production') {
          connectionInfo.url = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PUBLIC_KEY_PROD}`;
        } else {
          connectionInfo.url = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_PUBLIC_KEY_DEV}`;
        }
        const provider = new ethers.providers.JsonRpcProvider(connectionInfo);
        const gasPrice = await provider.getGasPrice();
        setL1GasPrice(
          parseFloat(ethers.utils.formatUnits(gasPrice.toString(), 'gwei'))
        );
        const txHash = txLink.substr(txLink.indexOf('0x'));
        const txReceipt = await provider.getTransactionReceipt(txHash);
        const txData = await provider.getTransaction(txHash);
        const no0x = txData.data.substr(2);
        let zeroBytes = 0;
        let dataBytes = 0;
        for (let j = 0; j < no0x.length; j += 2) {
          const curByte = no0x.substr(j, 2);
          if (curByte === '00') {
            zeroBytes++;
          } else {
            dataBytes++;
          }
        }
        setZeroBytes(zeroBytes);
        setDataBytes(dataBytes);
        const l2Gas =
          (zeroBytes * 4 +
            dataBytes * 16 +
            gasComparisonValues.BATCH_SUBMISSION_GAS) *
          gasComparisonValues.OPTIMISM_PREMIUM;
        const gasSaved = (txReceipt.gasUsed.toNumber() / l2Gas).toFixed(1);
        const l1Fee = await feeInUSD(txData.gasPrice, txReceipt.gasUsed);
        setL2UsdPrice((l1Fee / gasSaved).toFixed(2));
        setIsCalculating(false);
        setL1Gas(txReceipt.gasUsed);
        setL2Gas(l2Gas);
        setGasSaved(gasSaved);
      } catch (err) {
        console.error(err);
      }
    },
    [etherscanLink, toast]
  );

  const feeInUSD = async (gasPrice, gasUsed) => {
    const result = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD',
      { timeout: 8e3 } // 8 second timeout
    );
    const feeInWei = gasPrice.mul(gasUsed);
    const feeInEth = ethers.utils.formatUnits(feeInWei, 'ether');
    return result.data.ethereum.usd * parseFloat(feeInEth);
  };

  const handleChange = (event) => {
    setEtherscanLink(event.target.value);
  };

  const isValidLink = (link) => {
    const hashTester = new window.RegExp(/^0x([A-Fa-f0-9]{64})$/, 'gm');
    return (
      link.indexOf('etherscan') > 0 &&
      link.indexOf('tx/0x') > 0 &&
      hashTester.test(link.slice(24))
    );
  };

  React.useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      handleFormSubmit(null, options.chainlink.link);
    }
  }, [handleFormSubmit, isInitialized]);

  return (
    <Box m="0 auto" maxW={['none', '600px', null, 'none']}>
      <Box mb={8}>
        <Heading fontSize="1.4rem" fontWeight={400} mb={0}>
          How much can Optimistic Ethereum reduce your gas pain?
        </Heading>
        <Text fontSize="1rem" mb={0} mt={2}>
          Paste an Etherscan link to a transaction or select a preset
          transaction.{' '}
        </Text>
        <Text mt={8}>
          Note: These comparisons are calculated based on current network
          congestion (ie: current gas price). Some of the parameters will be
          reduced in the coming months as we optimize the system, and
          dramatically reduced when{' '}
          <Link
            href="https://ethereum.org/en/eth2/shard-chains/"
            isExternal={true}
          >
            sharding
          </Link>{' '}
          goes live on mainnet.
        </Text>
      </Box>
      <Box
        boxShadow="-8px 8px 20px 0px #ededed"
        paddingTop="2rem"
        paddingBottom="2rem"
        px={['1rem', null, '2rem']}
      >
        <Box
          py={4}
          display="flex"
          flexDirection={['column', null, null, 'row']}
          pos="relative"
        >
          <Image
            pos="absolute"
            w="80px"
            top={'-50px'}
            right={'-60px'}
            transform="rotate(10deg)"
            src={coloredEthLogo}
            alt="Ethereum logo"
          ></Image>
          <Box
            w={['100%', null, null, '45%']}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box
              className={styles.presets}
              d="flex"
              justifyContent="space-around"
              height="100%"
              alignItems="center"
            >
              {Object.entries(options).map(([id, option]) => {
                return (
                  <input
                    key={option.name}
                    type="image"
                    alt={option.name}
                    src={option.iconURL}
                    onClick={() => handleInputOverride(id)}
                    className={
                      styles.button + ' ' + (id === txId ? styles.active : '')
                    }
                  ></input>
                );
              })}
            </Box>

            <Box>
              <Input
                mt={8}
                mb={4}
                type="link"
                name="etherscan-link"
                placeholder="https://etherscan.io/tx/0x..."
                value={etherscanLink}
                onChange={handleChange}
              ></Input>
              <Button
                padding="8px"
                color="white"
                background="#f01a37"
                fontFamily="'Roboto'"
                fontWeight="bold"
                fontSize="17px"
                letterSpacing="0.7px"
                width="100%"
                height="50px"
                border="none"
                margin="0 auto"
                cursor="pointer"
                _hover={{
                  bg: '#f01a37',
                }}
                onClick={handleFormSubmit}
              >
                Calculate
              </Button>
            </Box>
          </Box>
          <Box
            minH="260px"
            w={['100%', null, null, '65%']}
            ml={[0, null, null, 12]}
            mt={(0, null, null, 8)}
            d="flex"
            flexDir="column"
          >
            <Divider display={'none'} />
            {showHeading && (
              <>
                {txId && <Text my={0}>{options[txId].name}</Text>}
                <Heading mt={0} fontWeight="400" fontSize="1.8rem">
                  {txId ? options[txId].desc : 'User transaction'}
                </Heading>
              </>
            )}
            {isCalculating ? (
              <Center p={8}>
                <Spinner w="100px" h="100px" />
              </Center>
            ) : (
              <GasCalcResults
                l1Gas={addCommas(l1Gas)}
                l2Gas={addCommas(l2Gas)}
                gasSaved={gasSaved}
                l2UsdPrice={l2UsdPrice}
              />
            )}
          </Box>
        </Box>
        <Accordion allowToggle defaultIndex={0}>
          <AccordionItem>
            <AccordionButton
              background="transparent"
              border="none"
              mt={2}
              w="auto"
              cursor="pointer"
              _hover={{ background: 'transparent' }}
              px={0}
            ></AccordionButton>
            <Divider />
            <AccordionPanel px={0} pt={4} lineHeight="1.7" fontSize="0.9rem">
              <Heading fontWeight="300" size="lg" mb={2}>
                {txId ? options[txId].desc : 'Example'} gas calculation
              </Heading>
              <Text fontSize="1.2rem" mt={6}>
                <ChatIcon mr={2} className={styles.iconBounce} />
                Hover over each variable to reveal more info!
              </Text>
              <SimpleGrid
                mt={6}
                columns={[2, null, null, null, 1]}
                spacing={12}
                gridTemplateColumns={['1fr', null, null, '45fr 65fr']}
              >
                <Box d="flex" flexDir="column" justifyContent="stretch">
                  <Heading fontWeight="300" size="md" mt={0}>
                    Formula
                  </Heading>
                  <Formula />
                </Box>
                <Box d="flex" flexDir="column" justifyContent="stretch">
                  <Heading fontWeight="300" size="md" mt={0}>
                    {txId ? options[txId].desc : 'User transaction'}
                  </Heading>
                  <Formula
                    variables={{
                      zeroDataBytes: zeroBytes,
                      nonZeroDataBytes: dataBytes,
                      layer1GasPrice: l1GasPrice,
                      layer2GasPrice: 0,
                      layer2Gas: l1Gas * 5,
                    }}
                  />
                </Box>
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};

export default GasCalcSection;
