import * as SentryInit from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const getSentry = () => {
  if (window.location.host === 'optimism.io') {
    SentryInit.init({
      dsn:
        'https://0a16716bdc71460bb45ac8965c2eed45@o568345.ingest.sentry.io/5733974',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    });
    return SentryInit;
  }
  return { captureException: () => {} };
};

export const Sentry = getSentry();
