import React from 'react';
import {
  Heading,
  Box,
  Text,
  Spinner,
  Link,
  Divider,
  Image,
  Center
} from '@chakra-ui/react';
import DateTime from 'luxon/src/datetime.js';
import Interval from 'luxon/src/interval.js';
import { networkStatuses } from '../../pages/NetworkStatus';

function NetworkCard({
  networkName,
  networkState,
  millisSinceLastBatch,
  smallScreen
}) {
  const intervalSinceLastBatch = Interval.fromDateTimes(
    DateTime.fromMillis(Date.now() - millisSinceLastBatch),
    DateTime.fromMillis(Date.now())
  );

  let formattedInterval;
  // 86_400_000 == 1 day in millis
  if (intervalSinceLastBatch.count() >= 86_400_000) {
    // if over 1 day, display as days
    formattedInterval = intervalSinceLastBatch
      .toDuration()
      .toFormat("d 'days and' h 'hours'");
    // 3_600_000 == 1 hour in millis
  } else if (intervalSinceLastBatch.count() >= 3_600_000) {
    // if over 1 hour, display as hours & minutes
    formattedInterval = intervalSinceLastBatch
      .toDuration()
      .toFormat("h 'hours and' m 'minutes'");
  } else if (intervalSinceLastBatch.count() >= 60_000) {
    formattedInterval = intervalSinceLastBatch
      .toDuration()
      .toFormat("m 'minutes'");
  } else {
    formattedInterval = 'less than a minute';
  }

  const color =
    networkState.status === networkStatuses.HAPPY ||
    networkState.status === networkStatuses.CONTENT
      ? 'green.500'
      : networkState.status === networkStatuses.SAD
      ? 'red.500'
      : 'blackAlpha.800';

  return (
    <Box
      borderWidth="4px"
      p={4}
      fontSize="1rem"
      borderRadius={10}
      textAlign="center"
      borderColor={color}
      borderStyle="solid"
      w="100%"
      minH="400px"
    >
      <Heading
        as="h2"
        size="xl"
        fontWeight="500"
        mb={6}
        color={
          networkState.status === networkStatuses.HAPPY
            ? 'green.500'
            : 'blackAlpha.800'
        }
      >
        {networkName}
      </Heading>
      <Center mb={2} lineHeight="1.1" minH="200px">
        {networkState.status ? (
          <Image
            maxW="200px"
            py="20px"
            src={
              networkState.status === networkStatuses.HAPPY
                ? './images/emojis/sunglasses.png'
                : networkState.status === networkStatuses.CONTENT
                ? './images/emojis/smile.png'
                : networkState.status === networkStatuses.CONFUSED
                ? './images/emojis/thinking.png'
                : './images/emojis/sad.png'
            }
          />
        ) : (
          <Spinner w="90px" h="90px" />
        )}
      </Center>
      <Box>
        {networkState.status && (
          <>
            <Text
              d="block"
              fontSize={'1.6rem'}
              lineHeight="normal"
              fontWeight="bold"
              my={4}
              color={color}
            >
              {networkState.heading}
            </Text>
            <Box
              textAlign="left"
              d="inline-block"
              mx="auto"
              fontWeight="bold"
              maxW="220px"
              color={
                networkState.status === networkStatuses.HAPPY
                  ? 'green.500'
                  : 'blackAlpha.800'
              }
            >
              <Text mt={2}>{networkState.sequencer} </Text>
              <Text mt={2} mb={0}>
                {networkState.batchSubmitter}{' '}
              </Text>
            </Box>
            <Text mt={6}>
              {millisSinceLastBatch
                ? `The last transaction batch was submitted ${formattedInterval} ago.`
                : ''}
            </Text>
          </>
        )}

        {networkState.status && networkState.status !== networkStatuses.HAPPY && (
          <Text d="inline">
            &nbsp;For updates, please check our{' '}
            <Link href="https://discord.optimism.io" isExternal={true}>
              discord
            </Link>
            .
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default NetworkCard;
