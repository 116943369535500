import React from 'react';
import { ethers } from 'ethers';
import { useTable, usePagination } from 'react-table';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Center,
  Text,
  Select,
  FormLabel,
  HStack,
  Box,
  Spinner
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { DateTime } from 'luxon';
import Container from '../../components/Container';
import { PageHeader } from '../../components/Headers';
import history from './SNX-6-22-2021.json';

const tokens = ['SNX', 'ETH'];
const regenesisDates = ['6-22-2021', '5-10-2021'];

export const shortenHash = (address: string = '', charLength: number = 8) =>
  address.slice(0, charLength + 2) +
  '...' +
  address.slice(address.length - charLength, address.length);

history.sort((a, b) => b.initiatedTime - a.initiatedTime);

const columns = [
  {
    Header: 'Address',
    accessor: 'account',
    Cell: ({ value }) => shortenHash(value)
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value }) => {
      return (+ethers.utils.formatEther(value)).toFixed(6);
    }
  },
  {
    Header: 'Initiated',
    accessor: 'initiatedTime',
    Cell: ({ value }) => {
      return DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_SHORT);
    }
  },
  {
    Header: 'Explorer',
    accessor: 'l1TransactionHash',
    Cell: ({ value }) => {
      return value ? (
        <Center>
          <Link href={`https://etherscan.io/tx/${value}`} isExternal>
            <ExternalLinkIcon />
          </Link>
        </Center>
      ) : (
        <Center>...</Center>
      );
    }
  }
];

function TxHistory() {
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(history);
  const tableInstance = useTable({ columns, data: tableData }, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;
  const [selectedToken, setSelectedToken] = React.useState('SNX');
  const [selectedDate, setSelectedDate] = React.useState('6-22-2021');

  const handleTokenSelect = React.useCallback(
    async e => {
      setLoading(true);
      setSelectedToken(e.target.value);
      try {
        const newData = await import(
          `./${e.target.value}-${selectedDate}.json`
        );
        setTableData(newData.default);
      } catch (err) {
        if (err.message.includes('Cannot find module')) {
          setTableData([]);
        }
        console.error(err);
      }
      setLoading(false);
    },
    [selectedDate]
  );

  const handleDateSelect = React.useCallback(
    async e => {
      setLoading(true);
      setSelectedDate(e.target.value);
      try {
        const newData = await import(
          `./${selectedToken}-${e.target.value}.json`
        );
        setTableData(newData.default);
      } catch (err) {
        if (err.message.includes('Cannot find module')) {
          setTableData([]);
        }
        console.error(err);
      }
      setLoading(false);
    },
    [selectedToken]
  );

  return (
    <Container maxW="1200px">
      <PageHeader>Historical withdrawals</PageHeader>
      <Text mb={8} fontSize="1.2rem">
        This table contains all completed {selectedToken} mainnet withdrawals up
        until a regenesis that occured on{' '}
        {new Date(selectedDate).toLocaleDateString()}.
      </Text>
      <HStack spacing={8}>
        <Box>
          <FormLabel mt={8}>Asset</FormLabel>
          <Select
            placeholder="Select asset"
            onChange={handleTokenSelect}
            mb={8}
            value={selectedToken}
          >
            {tokens.map(token => (
              <option key={token} value={token}>
                {token}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <FormLabel mt={8}>Regenesis date</FormLabel>
          <Select
            placeholder="Select date"
            onChange={handleDateSelect}
            mb={8}
            value={selectedDate}
          >
            {regenesisDates.map(date => (
              <option key={date} value={date}>
                {new Date(date).toLocaleDateString()}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>
      {loading ? (
        <Center pt="100px" w="100%">
          <Spinner size="xl" />
        </Center>
      ) : tableData.length ? (
        <Table {...getTableProps()} variant="striped" size="sm" minW="800px">
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      textAlign={
                        column.Header.includes('Explorer') ? 'center' : 'left'
                      }
                    >
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Text>No withdrawals found</Text>
      )}
    </Container>
  );
}

export default TxHistory;
