import erc20 from './abis/erc20.json';
import SynthetixBridgeToBase from './abis/SynthetixBridgeToBase.json';
import SynthetixBridgeToOptimism from './abis/SynthetixBridgeToOptimism.json';
import xDomainMessenger from './abis/XDomainMessenger.json';
import OVM_L1StandardBridge from './abis/OVM_L1StandardBridge.json';
import OVM_L2StandardBridge from './abis/OVM_L2StandardBridge.json';
import L2DAITokenBridge from './abis/L2DAITokenBridge.json';
import OVM_StateCommitmentChain from './abis/OVM_StateCommitmentChain.json';

const abis = {
  l1: {
    standardBridge: OVM_L1StandardBridge,
    snxBridge: SynthetixBridgeToOptimism,
    OVM_StateCommitmentChain,
  },
  l2: {
    standardBridge: OVM_L2StandardBridge,
    snxBridge: SynthetixBridgeToBase,
  },
  erc20,
  xDomainMessenger,
};

export default abis;
